.nav-link {
	cursor: pointer;
}

.ezu {
	color: red;
}

.coopernico {
	color: #2c6150;
}

.loadingOverlay {
	position: absolute;
	z-index: 1001;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.8);
}

.col-form-label {
    font-size: 0.9rem !important;
}

.input-help {
	color: #606060;
    font-size: 0.8rem;
}

.form-group {
  position: relative;
}

.form-control, .form-select {
	color:#424242 !important;
	font-size: 0.8rem !important;
}

.form-required {
	font-size: 15px;
    font-weight: 700;
	position: absolute;
	top: 10px;
    right: 0px;
    color:rgba(255, 37, 0, 0.9);
}

.form-required::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 700;
	margin-right: 5px;
	font-size: 7px;
	font-style: normal;
	font-variant-caps: normal;
	font-variant-ligatures: normal;
	height: 7px;
	line-height: 8px;
	text-rendering: auto;
	width: 7px;
	-webkit-font-smoothing: antialiased;
}

.form-empty {
	color:rgba(255, 37, 0, 0.9);
	transition: color 0.5s
}

.form-empty::before {
	content: "\f069";
}

.form-full {
	color:rgba(55, 55, 55, 0.2);
	transition: color 0.5s
}

.form-full::before {
	content: "\f069";
}